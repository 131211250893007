import React from 'react'
import './footer.css'

function Footer() {
   return (
      <div className="footer">
         <a href="mailto:info@violentwavesrec.com" className="footer-item fade">
            contact
         </a>
         <div className="footer-item">
            |
         </div>
         <div className="footer-item">
            violent waves recordings
         </div>

      </div>
   )
}

export default Footer
