import React from 'react';
import './mainName.css'

function MainName() {

   return (
      <div>
         <a href="mailto:info@violentwavesrec.com" className="main-name">
            <div className="fade">violent</div>
            <div className="fade">waves</div>
            <div className="fade">rec.</div>
         </a>
      </div>

   )
}

export default MainName
