import React from 'react'
import './App.css';
import MainName from "./components/mainName/mainName";
import Navigation from "./components/navigation/navigation";
import Footer from "./components/footer/footer";

function App() {
   return (
      <div className="App">
         <div className="overlay">
            <Navigation/>
            <MainName/>
            <Footer/>
         </div>
      </div>
   );
}

export default App;
