import React from 'react'
import './navigation.css'

function Navigation() {

   return (
      <div className="navigation-wrapper">
         <div className="item">
            recording
         </div>
         <div className="item">
            |
         </div>
         <div className="item">
            mixing
         </div>
         <div className="item">
            |
         </div>
         <div className="item">
            editing
         </div>
      </div>
   )
}

export default Navigation
